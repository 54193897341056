import React from 'react';
import styled from '@emotion/styled/macro';
import PropTypes from 'prop-types';

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2.5rem 1rem;

  text-align: center;
`;

const ImageWrapper = styled('div')`
  img, svg {
    max-width: 250px;
    width: 100%;
    height: auto
  }

  /* Idea to start making unDraw images with brand colors */
  [fill="#6c63ff"] {
    fill: ${({ theme }) => theme.palette.primary.main};
  }
`;

const Title = styled('p')`
  font-size: 18px;
  font-weight: 700;
  margin: 1rem 0;

  max-width: 100%;
`;

const Subtitle = styled('p')`
  color: #666;
  font-size: 14px;
  margin-top: 1rem 0;

  max-width: 100%;
`;

const ImageText = ({ title, imgEl: ImgEl = 'img', src, subtitle, ...rest }) => (
  <Wrapper {...rest}>
    <ImageWrapper>
      <ImgEl src={src} alt="" />
    </ImageWrapper>
    {title && <Title>{title}</Title>}
    {subtitle && <Subtitle>{subtitle}</Subtitle>}
  </Wrapper>
);

ImageText.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.any,

  // Use either src or imgEl
  src: PropTypes.any,
  imgEl: PropTypes.any,
};

export default ImageText;
