import React from 'react';
import styled from '@emotion/styled/macro';
import Navbar from 'containers/WebNavbar';
import { css, Global } from '@emotion/react';
import PropTypes from 'propTypes';
import FullScreenHeader from './FullScreenHeader';

const GlobalStyle = () => {
  return (
    <Global styles={css`
      html{
        padding-bottom: 0 !important;
      }
      body {
        background: #fff !important;
        padding-bottom: 0 !important;
      }
    `}
    />
  );
};

const RootContainer = styled('div')`
  max-width: ${({ theme }) => theme.maxWidth};
  margin: 0 auto;
  position: relative;
  width: 100%;
  height: 100%;

  padding-top: 8rem;

  display: flex;
  justify-content: flex-start;
  flex-direction: column;
`;

const Content = styled('div')`
  max-width: ${({ theme }) => theme.maxWidth};
  min-width: 320px;
`;

const WebFullScreenLayout = ({ children, backUrl = '#', title = '', rightComponent = <></> }) => {
  return (
    <>
      <GlobalStyle />
      <RootContainer>
        <Navbar />
        <FullScreenHeader
          backUrl={backUrl}
          title={title}
          rightComponent={rightComponent}
        />
        <Content id="content">{children}</Content>
      </RootContainer>
    </>
  );
};


WebFullScreenLayout.propTypes = {
  backUrl: PropTypes.string,
  title: PropTypes.string,
  rightComponent: PropTypes.component,
};



export default WebFullScreenLayout;
