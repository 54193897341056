import React, { useEffect, useRef } from 'react';
import { useFieldArray } from 'react-final-form-arrays';
import Grid from 'components/Grid';
import useVideoUploader from 'hooks/useVideoUploader';
import PropTypes from 'prop-types';
import useObjectUrl from 'hooks/useObjectUrl';

import SubmitBlocker from 'components/FinalForm/Fields/SubmitBlocker';
import PreviewVideo from './PreviewVideo';
import PreviewImage from './PreviewImage';
import Removable from './Removable';

const identify = value => {
  return !!value.video // This type is used for storing
    || value.type === 'video' // This type is used when server returns
    || (value instanceof File && value.type.match('video/.*')) // New upload
    ? 'video'
    : 'image';
};

const MediaAttachment = ({ idx, name, value, fields: { update, remove } }) => {

  const { uploading, progress, upload, reset } = useVideoUploader();
  const hasWorked = useRef(false);

  const onRemove = () => {
    if (value.id) {
      update(idx, { ...value, delete: true }); // Already stored files
    } else {
      remove(idx); // New files
    }
  };

  // On onmount, make sure we're no longer uploading
  useEffect(() => {
    return () => {
      reset();
      hasWorked.current = false;
    };
  }, [reset]);

  // Transform files to upload format
  useEffect(() => {
    if (!(value instanceof File) || hasWorked.current) {
      return;
    }

    hasWorked.current = true;

    if (value.type.match('video/.*')) {
      upload(value)
        .then(({ cdnUrl, originalFilename }) => update(idx, { video: cdnUrl, name: originalFilename, position: idx }))
        .catch(() => remove(idx));
    } else {
      update(idx, { image: value, position: idx });
    }
  }, [idx, remove, update, upload, uploading, value]);

  const type = identify(value);

  const src = useObjectUrl(value instanceof File ? value : value.image) || value?.src;
  const srcSet = value?.srcSet;

  return (
    <Removable onClick={onRemove}>
      {type === 'video'
        ? <PreviewVideo uploading={uploading} progress={progress} name={value.name} poster={value.poster} />
        : <PreviewImage src={src} srcSet={srcSet} />}
      {type === 'video' && uploading && <SubmitBlocker name={`${name}.uploading`}>Wait for upload to finish</SubmitBlocker>}
    </Removable>
  );
};

MediaAttachment.propTypes = {
  fields: PropTypes.shape({
    update: PropTypes.func.isRequired,
    remove: PropTypes.func.isRequired,
  }),

  value: PropTypes.oneOfType([
    // New file, not yet uploaded nor formatted
    PropTypes.instanceOf(File),

    // (New) Formatted video ready for server
    PropTypes.shape({
      name: PropTypes.string,
      video: PropTypes.string.isRequired,
    }),

    // (New) Formatted image ready for server
    PropTypes.shape({
      image: PropTypes.instanceOf(File).isRequired,
    }),

    // File from server
    PropTypes.shape({
      id: PropTypes.any.isRequired,
      src: PropTypes.string,
      srcSet: PropTypes.string,
      poster: PropTypes.string,
      type: PropTypes.oneOf(['image', 'video']).isRequired,
    }),
  ]),

  name: PropTypes.string,
  idx: PropTypes.number,
};

const MediaExhibit = ({ name = 'media' }) => {
  const { fields } = useFieldArray(name);

  return (
    <Grid container spacing={2}>
      {fields.map((fieldName, idx) => (
        <Grid item alignSelf="flex-start" key={fieldName} hidden={fields.value[idx]?.delete}>
          <MediaAttachment key={fieldName} name={fieldName} value={fields.value[idx]} idx={idx} fields={fields} />
        </Grid>
      ))}
    </Grid>
  );
};

MediaExhibit.propTypes = {
  name: PropTypes.string,
};

export default MediaExhibit;
