import React from 'react';
import styled from '@emotion/styled/macro';
import NavLink from 'components/NavLink';
import PropTypes from 'prop-types';

const StyledTab = styled(NavLink)`
  padding: 0.5rem 0.9rem;

  margin: 0.2rem;

  @media(max-width: 400px) {
    padding: 0.4rem 0.5rem;
    font-size: 0.9rem;
  }

  color: inherit;
  text-decoration: none;
  font-weight: 600;
  text-align: center;
  cursor: pointer;
  border-radius: 16px;

  &:hover {
    background-color: #eee;
  }

  &.active {
    background-color: ${({ theme }) => theme.palette.primary.main};
    color: ${({ theme }) => theme.palette.primary.contrastText};
  }
`;

const Tab = ({ replace = true, ...rest }) => <StyledTab replace={replace} {...rest} />;

Tab.propTypes = {
  replace: PropTypes.bool,
  to: PropTypes.any,
};

const SpanTab = StyledTab.withComponent('span');

export const BasicTab = React.forwardRef(({ isActive, className, ...rest }, ref) => {
  const cn = isActive && isActive() ? `active ${className}` : className;

  return <SpanTab ref={ref} className={cn} {...rest} />;
});

BasicTab.propTypes = {
  isActive: PropTypes.func,
};

export default Tab;
