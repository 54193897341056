import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import NavLink from 'components/NavLink';
import { authActions, authSelectors } from 'redux/auth';
import { useDispatch, useSelector } from 'react-redux';
import ListItemIcon from '@mui/material/ListItemIcon';
import { useTranslation } from 'react-i18next';
import Button from 'components/Button';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import UserIcon from 'components/Icons/User';
import styled from '@emotion/styled/macro';
import SignOutIcon from 'components/Icons/SignOut';
import CogIcon from 'components/Icons/Cog';
import { css } from '@emotion/react';


const StyedListItemIcon = styled(ListItemIcon)`
  color: inherit;
`;

const UserMenuItem = ({ startIcon, children, ...rest }) => (
  <MenuItem {...rest}>
    <StyedListItemIcon>{startIcon}</StyedListItemIcon>
    {children}
  </MenuItem>
);

const MenuButtonContent = styled('span')`
  max-width: 200px;
  overflow: hidden;
  justify-content: flex-start;
  text-overflow: ellipsis;

  @media(max-width: 600px) {
    max-width: 100px;
  }
`;

const menuButtonConfig = {
  shouldForwardProp: prop => !['compact'].includes(prop),
};

const MenuButton = styled(Button, menuButtonConfig)`
  ${({ compact }) => compact && css`

    min-width: initial;

    .MuiButton-startIcon {
      margin-right: 0;
    }

    .MuiButton-endIcon {
      margin-left: 0;
    }

    ${MenuButtonContent} {
      display: none;
    }
  `}
`;

UserMenuItem.propTypes = {
  startIcon: PropTypes.node,
};

const AuthenticatedUserMenu = ({ compact = false, ...rest }) => {

  const { t } = useTranslation(['component']);
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const button = useRef();

  const { firstName, lastName } = useSelector(authSelectors.getMeUser);
  const authMemberId = useSelector(authSelectors.getMemberId);

  const handleToggle = () => setOpen(o => !o);

  const onClickAway = () => setOpen(false);
  const onClose = () => setOpen(false);

  const logout = () => dispatch(authActions.logout());

  const name = [firstName, lastName].filter(v => !!v).join(' ');

  return (
    <>
      <MenuButton compact={compact} variant="text" color="inherit" startIcon={<UserIcon />} endIcon={<ExpandMoreIcon />} ref={button} aria-controls={open ? 'authenticated-user-menu' : undefined} {...rest} aria-haspopup="true" onClick={handleToggle} nowrap>
        <MenuButtonContent translate="no">{name}</MenuButtonContent>
      </MenuButton>
      <Popper open={open} anchorEl={button.current} transition disablePortal placement="top-end">
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={onClickAway}>
                <MenuList id="authenticated-user-menu">
                  <UserMenuItem onClick={onClose} component={NavLink} to={`/member/${authMemberId}`} startIcon={<UserIcon fontSize="small" />}>{t('userMenu.myProfile')}</UserMenuItem>
                  <UserMenuItem onClick={onClose} component={NavLink} to="/preferences" startIcon={<CogIcon fontSize="small" />}>{t('userMenu.preferences')}</UserMenuItem>
                  <UserMenuItem onClick={logout} startIcon={<SignOutIcon fontSize="small" />}>{t('userMenu.logout')}</UserMenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

AuthenticatedUserMenu.propTypes = {
  compact: PropTypes.bool,
};

export default AuthenticatedUserMenu;
