import React from 'react';
import PropTypes from 'prop-types';
import fieldRenderProps from 'components/FinalForm/fieldRenderProps';
import GuavaSelectField from 'components/Fields/SelectField';
import { Field } from 'react-final-form';
import withFlatArgs from '../withFlatArgs';

const SelectFieldComponent = withFlatArgs(GuavaSelectField, 'SelectField');
const SelectField = (args) => <Field component={SelectFieldComponent} {...args} />;


SelectField.propTypes = {
  ...fieldRenderProps,
  native: PropTypes.bool,
};

export default SelectField;
