import React from 'react';
import Button from 'components/Button';
import styled from '@emotion/styled/macro';
import { css } from '@emotion/react';
import PropTypes from 'prop-types';

const gray = [
  //                                                          1. Basic
  { text: '#333', background: 'rgba(0, 0, 0, 0.04)' }, // 2. Basic hover  | 1. Important basic
  { text: '#333', background: 'rgba(0, 0, 0, 0.06)' }, // 3. Basic active | 2. Important basic hover
  { text: '#333', background: 'rgba(0, 0, 0, 0.08)' }, //                 | 3. Important basic active
];

const generateButtonStyles = ({ color = gray, active, darker }) => {

  const light = css`
    color: ${color[0].text};
    background: ${color[0].background};
  `;

  const dark = css`
    color: ${color[1].text};
    background: ${color[1].background};
  `;

  const darkest = css`
    color: ${color[2].text};
    background: ${color[2].background};
  `;

  const currentlyUsed = !darker ? [light, dark] : [dark, darkest];

  return css`
    color: ${darker ? '#333' : '#666'};
    outline: none;

    &:hover, &:focus {
      ${currentlyUsed[0]}
    }

    ${active && css`
      ${currentlyUsed[0]}
      &:hover, &:focus {
        ${currentlyUsed[1]}
      }
    `}
  `;
};

const config = {
  shouldForwardProp: prop => !['color', 'active', 'darker', 'variant'].includes(prop),
};

// Bad name was chosen for the "color" attribute and it conflicts with
// @mui Button defaults. Let's pass inherit to fix those problems.
const ButtonFixer = React.forwardRef((args, ref) => <Button color="inherit" ref={ref} {...args} />);

const ColorfulButton = styled(ButtonFixer, config)`
  border-radius: 4px;
  border: none;
  background: none;

  font-weight: 600;

  white-space: nowrap;

  ${({ iconOnly }) => iconOnly && css`
    min-width: auto;
    .MuiButton-startIcon { margin-right: 0; }
  `}

  ${(props) => !props.disabled && generateButtonStyles(props)}
`;

ColorfulButton.propTypes = {
  color: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string,
    background: PropTypes.string,
  })),

  active: PropTypes.bool,
  darker: PropTypes.bool,

  iconOnly: PropTypes.bool,
};

export default ColorfulButton;
