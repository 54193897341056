import React, { useRef, useLayoutEffect } from 'react';
import styled from '@emotion/styled/macro';
import MaxWidthContainer from 'components/MaxWidthContainer';
import LayoutLogo from 'containers/LayoutLogo';
import PropTypes from 'prop-types';
import history from 'providers/history';
import NavLink from 'components/NavLink';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import UserIcon from 'components/Icons/User';
import NotificationsIconButton from 'containers/NotificationsIconButton';
import useMediaQuery from '@mui/material/useMediaQuery';
import useResize from 'hooks/useResize';
import sizeMe from 'react-sizeme';
import Grid from 'components/Grid';

const Wrapper = styled('div')`
  width: 100%;
  min-height: 52px;
  min-height: var(--navbar-height);
  background-color: #fff;
  box-shadow: 0 1px 4px 0 rgba(0,0,0,0.05),
              0 2px 6px 0 rgba(0,0,0,0.05);

  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  z-index: ${({ theme }) => theme.zIndex.navbar};

  @supports (top: env(safe-area-inset-top, 0)){
    padding-top: env(safe-area-inset-top, 0);
  }

  display: flex;
  align-items: flex-end;
  padding-bottom: 4px;
`;

const StyledNavlink = styled(NavLink)`
  display: flex;
  align-items: center;
  max-width: 150px;

  height: 40px;

  @media(max-width: 500px) { max-width: 100px; }
  @media(max-width: 400px) { max-width: 75px; }
  @media(max-width: 320px) { max-width: 70px; }
`;

const LogoBlock = ({ onLoad }) => <StyledNavlink to="/"><LayoutLogo onLoad={onLoad} /></StyledNavlink>;

LogoBlock.propTypes = {
  onLoad: PropTypes.func,
};

const InnerContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;

  position: relative;
`;

const Block = sizeMe()(styled('div')`
  max-width: 33%;
  white-space: nowrap;
`);

const Title = styled('div')`
  text-align: center;

  position: absolute;
  pointer-events: none;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  align-items: center;

  span {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
  }

  font-weight: 700;
  font-size: 1.2rem;
  @media(max-width: 420px) { font-size: 1.1rem; }
  @media(max-width: 375px) { font-size: 1rem; }
  @media(max-width: 360px) { font-size: 0.9rem; }
  @media(max-width: 320px) { font-size: 0.8rem; }
`;

// Trickiest part with the navbar is to keep the text
// centered while giving the sides maximum amount of space
const MobileNavbar = ({ left = 'logo', leftTo, title, right = 'actions' }) => {

  const navbar = useRef();

  const smallScreen = useMediaQuery('(max-width: 360px)');

  const containerRef = useRef();
  const titleRef = useRef();

  const equalizer = () => {
    if (!containerRef.current) {
      return;
    }
    const widths = Array.from(containerRef.current.children).map(el => el.clientWidth);
    const sides = Math.max(...widths) * 2;
    const padding = 8;
    titleRef.current.style.width = `${containerRef.current.clientWidth - sides - padding * 2}px`;
  };
  useResize(equalizer, { passive: true });

  const leftValues = {
    logo: <LogoBlock onLoad={equalizer} />,
    back: (
      <IconButton edge="start" color="inherit" onClick={() => leftTo ? history.push(leftTo) : history.go(-1)}>
        <ArrowBackIosRoundedIcon />
      </IconButton>
    ),
    close: (
      <IconButton edge="start" color="inherit" onClick={() => leftTo ? history.push(leftTo) : history.go(-1)}>
        <CloseIcon />
      </IconButton>
    ),
  };

  const rightValues = {
    actions: (
      <Grid container spacing={0.5} wrap="nowrap">
        <Grid item>
          <NotificationsIconButton component={NavLink} to="/notifications" size={smallScreen ? 'small' : 'medium'} />
        </Grid>
        <Grid item>
          <IconButton color="inherit" onClick={() => history.push('/menu')}size={smallScreen ? 'small' : 'medium'}>
            <UserIcon />
          </IconButton>
        </Grid>
      </Grid>
    ),
  };

  useLayoutEffect(() => {
    const preventDefault = e => {
      e.preventDefault();
    };

    const currentNavbar = navbar.current;

    document.documentElement.classList.add('has-mobile-navbar');
    currentNavbar.addEventListener('touchmove', preventDefault, { passive: false });

    return () => {
      document.documentElement.classList.remove('has-mobile-navbar');
      currentNavbar.removeEventListener('touchmove', preventDefault, { passive: false });
    };
  }, []);

  return (
    <Wrapper ref={navbar}>
      <MaxWidthContainer>
        <Title ref={titleRef}><span>{title}</span></Title>
        <InnerContainer ref={containerRef}>
          <Block onSize={equalizer}>{leftValues[left]}</Block>
          <Block onSize={equalizer}>{rightValues[right]}</Block>
        </InnerContainer>
      </MaxWidthContainer>
    </Wrapper>
  );
};

MobileNavbar.propTypes = {
  left: PropTypes.oneOf(['logo', 'back', 'close']),
  leftTo: PropTypes.string,
  title: PropTypes.string,
  right: PropTypes.oneOf(['actions', null]),
};

export default MobileNavbar;
