import styled from '@emotion/styled/macro';
import Box from '@mui/material/Box';

const Card = styled(Box)`
  width: 100%;

  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.04);
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.05), 0px 2px 6px rgba(0, 0, 0, 0.05);
  border-radius: 4px;

  /* overflow: hidden; */
`;

export default Card;
