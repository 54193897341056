import React, { useEffect } from 'react';
import { useFieldArray } from 'react-final-form-arrays';
import Grid from 'components/Grid';
import PropTypes from 'prop-types';
import FileComponent from 'components/File';

const PreviewAttachment = ({ value, idx, fields: { update, remove } }) => {

  const onRemove = () => {
    if (value.id) {
      update(idx, { ...value, delete: true }); // Already stored files
    } else {
      remove(idx); // New files
    }
  };

  useEffect(() => {
    if (value instanceof File) {
      update(idx, { file: value });
    }
  }, [idx, update, value]);

  return (
    <FileComponent
      name={value.file?.name || value.name}
      size={value.file?.size || value.size}
      deletable
      onDelete={onRemove}
      preview
      fullWidth
    />
  );
};

PreviewAttachment.propTypes = {
  value: PropTypes.oneOfType([
    // New file, just picked
    PropTypes.instanceOf(File),

    // File from server
    PropTypes.shape({
      id: PropTypes.any.isRequired,
      name: PropTypes.string,
    }),

    // (New) file ready for server
    PropTypes.shape({
      file: PropTypes.instanceOf(File).isRequired,
    }),
  ]),

  fields: PropTypes.shape({
    update: PropTypes.func.isRequired,
    remove: PropTypes.func.isRequired,
  }),

  idx: PropTypes.number,
};

const AttachmentsExhibit = ({ name = 'attachments' }) => {
  const { fields: attachments } = useFieldArray(name);

  return (
    <Grid container spacing={2}>
      {attachments.map((fileName, idx) => !attachments.value[idx]?.delete && (
        <Grid item xs={12} key={fileName}>
          <PreviewAttachment key={fileName} idx={idx} value={attachments.value[idx]} fields={attachments} />
        </Grid>
      ))}
    </Grid>
  );
};

AttachmentsExhibit.propTypes = {
  name: PropTypes.string,
};

export default AttachmentsExhibit;
