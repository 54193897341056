import React from 'react';
import MuiTextField from '@mui/material/TextField';
import PropTypes from 'prop-types';

const TextField = ({ onBlur, onFocus, inputProps, inputMode, multiple, checked, readOnly, ...rest }) => (
  <MuiTextField
    inputProps={{
      ...(multiple && { multiple }),
      ...(checked && { checked }),
      ...(onBlur && { onBlur }),
      ...(onFocus && { onFocus }),
      ...(inputMode && { inputMode }),
      ...(readOnly && { readOnly }),
      ...inputProps,
    }}
    {...rest}
  />
);

TextField.propTypes = {
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  inputProps: PropTypes.object,
  inputMode: PropTypes.string,
  readOnly: PropTypes.bool,

  // Multiple and checked are needed when some other input type is being used
  multiple: PropTypes.bool,
  checked: PropTypes.bool,
};

export default TextField;
