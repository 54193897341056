import React, { useRef, useState, useMemo } from 'react';
import Grid from 'components/Grid';
import sizeMe from 'react-sizeme';
import styled from '@emotion/styled/macro';
import Button from 'components/Button';
import PropTypes from 'prop-types';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import List from '@mui/material/List';
import Drawer from '@mui/material/Drawer';
import Menu from '@mui/material/Menu';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@emotion/react';
import ListItemButton from '@mui/material/ListItemButton';

const Offscreen = styled('div')`
  position: fixed;
  left: -9999px;
  top: -9999px;
  visibility: hidden;
  opacity: 0;
  display: flex;
`;

const StyledWrapper = styled(Grid)`
  max-width: 100%;
  width: 100%;
  overflow: hidden;
`;


const Wrapper = sizeMe()(StyledWrapper);

const SizeMeDiv = sizeMe()('div');

const calculateBreakpoint = (widths, maxWidth, menuButtonWidth, padding = 16) => {
  let sum = 0;
  let idx = 0;

  while (idx < widths.length) {
    // Ignore zero widths, add paddings for rest
    const width = widths[idx] === 0 ? 0 : widths[idx] + padding;

    if (sum + width > maxWidth) {
      // Doesn't fit. Check if menuButton fits (if provided)
      return menuButtonWidth
        ? calculateBreakpoint(widths, maxWidth - menuButtonWidth - padding, null, padding)
        : idx;
    }

    sum += width;
    idx++;
  }

  return idx; // Everything fits
};

const renderButton = ({ key, ...item }) => <Button color="default" {...item} />;

const renderGridButton = ({ key, hidden, ...item }) => <Grid item key={key} hidden={hidden}>{renderButton(item)}</Grid>;

const renderMenuItem = ({ children, startIcon, onClick, closeMenu, key, ...rest }) => {

  const combinedOnClick = (...args) => {
    closeMenu(...args);
    if (onClick) {
      onClick(...args);
    }
  };

  return (
    <MenuItem key={key} onClick={combinedOnClick} {...rest}>
      {children}
    </MenuItem>
  );
};

const renderListItem = ({ children, startIcon, onClick, closeMenu, ...rest }) => {
  const combinedOnClick = (...args) => {
    closeMenu(...args);
    if (onClick) {
      onClick(...args);
    }
  };

  return (
    <ListItemButton onClick={combinedOnClick} {...rest}>
      {startIcon && <ListItemIcon>{startIcon}</ListItemIcon>}
      <ListItemText>{children}</ListItemText>
    </ListItemButton>
  );
};

const OverflowMenu = ({ items, menuProps, menuButtonProps, variant = 'menu' }) => {

  const theme = useTheme();
  const anchorEl = useRef();

  const { t } = useTranslation('component');

  const [open, setOpen] = useState(false);
  const [widths, setWidths] = useState([]);
  const [wrapperWidth, setWrapperWidth] = useState(0);
  const [menuButtonWidth, setMenuButtonWidth] = useState(0);

  const setWidth = (idx, size) => (
    setWidths(current => {
      if (current[idx] === size.width) {
        return current;
      }
      const next = current.slice();
      next[idx] = size.width;
      return next;
    })
  );

  const measureButtons = useMemo(() => (
    items.map((item, idx) => (
      <SizeMeDiv key={item.key} onSize={size => setWidth(idx, size)}>
        {renderButton(item)}
      </SizeMeDiv>
    ))
  ), [items]);

  // Count of how many items we can fit
  const breakpoint = useMemo(() => calculateBreakpoint(widths, wrapperWidth, menuButtonWidth, 16), [menuButtonWidth, widths, wrapperWidth]);

  const hasOverflow = breakpoint < items.length;

  return (
    <>

      <Offscreen>{measureButtons}</Offscreen>

      <Wrapper container spacing={2} wrap="nowrap" onSize={size => setWrapperWidth(size.width)} style={widths.length !== items.length ? { opacity: 0 } : {}}>

        {items.slice(0, breakpoint).map(item => renderGridButton(item))}

        {/* Keep menubutton always in DOM, otherwise we lose the ref */}
        <Grid item hidden={breakpoint >= items.length}>
          <SizeMeDiv onSize={size => setMenuButtonWidth(size.width)}>
            <Button nowrap variant="text" color="inherit" ref={anchorEl} onClick={() => setOpen(c => !c)} {...menuButtonProps}>
              {t('overflowMenu.nMore', { count: items.slice(breakpoint).filter(i => !i.hidden).length })}
            </Button>
          </SizeMeDiv>
        </Grid>

        {variant === 'menu' && hasOverflow && (
          <Menu
            open={open}
            onClose={() => setOpen(false)}
            anchorEl={anchorEl.current}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            MenuListProps={{ dense: true }}
            {...menuProps}
          >
            {items.slice(breakpoint).map(item => renderMenuItem({ closeMenu: () => setOpen(false), ...item }))}
          </Menu>
        )}

        {variant === 'drawer' && hasOverflow && (
          <Drawer variant="temporary" sx={{ zIndex: theme.zIndex.overflowMenu }} anchor="bottom" open={open} onClose={() => setOpen(false)}>
            <List>
              {items.map(item => renderListItem({ closeMenu: () => setOpen(false), ...item }))}
            </List>
          </Drawer>
        )}

      </Wrapper>
    </>
  );
};

OverflowMenu.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,

      hidden: PropTypes.bool,
    }),
  ).isRequired,

  menuProps: PropTypes.object,
  menuButtonProps: PropTypes.object,

  variant: PropTypes.oneOf(['menu', 'drawer']),
};

export default OverflowMenu;
