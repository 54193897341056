import React, { useEffect } from 'react';
import EventBox from 'components/EventBox';
import SectionTitle from 'components/SectionTitle';
import HorizontalScroller from 'components/HorizontalScroller';
import useFeature from 'hooks/useFeature';
import { useDispatch, useSelector } from 'react-redux';
import { calendarActions, calendarSelectors } from 'redux/calendar';
import NavLink from 'components/NavLink';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const EventBoxMapper = ({ id }) => {

  const event = useSelector(calendarSelectors.getEventSelector)(id);

  if (!event) {
    return null;
  }

  return (
    <EventBox
      id={event.id}
      name={event.name}
      cover={event.cover}
      startAt={event.startAt}
      allDay={event.allDay}
      endAt={event.endAt}
      hasTargets={event.hasTargets}
      targetUsersCount={event.targetUsersCount}
    />
  );
};

EventBoxMapper.propTypes = {
  id: PropTypes.any.isRequired,
};


const defaultSeeMore = {
  component: NavLink,
  to: '/calendar',
};

const CalendarWidget = ({ seeMore = defaultSeeMore, ...args }) => {

  const dispatch = useDispatch();
  const { t } = useTranslation(['calendar']);
  const { enabled } = useFeature('calendar');

  const { eventIds } = useSelector(state => {
    const upcoming = calendarSelectors.getUpcomingEvents(state);
    return {
      ...upcoming,
      eventIds: upcoming.eventIds.slice().splice(0, 3),
    };
  });

  useEffect(() => {
    if (enabled) {
      dispatch(calendarActions.getEvents({ status: 'upcoming' }));
    }
  }, [dispatch, enabled]);

  if (!eventIds || eventIds.length === 0 || !enabled) {
    return null;
  }

  return (
    <div {...args}>
      <SectionTitle seeMore={seeMore}>
        {t('calendarWidget.title')}
      </SectionTitle>
      <HorizontalScroller>
        {eventIds.splice(0, 3).map(id => <EventBoxMapper key={id} id={id} />)}
      </HorizontalScroller>
    </div>
  );
};

CalendarWidget.propTypes = {
  seeMore: PropTypes.object,
};


export default CalendarWidget;
