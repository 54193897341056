import React, { useRef, useState } from 'react';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Grow from '@mui/material/Grow';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import styled from '@emotion/styled/macro';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const PopperContent = styled(Paper)`
  padding: 0.25rem 0.5rem;
  font-size: 0.8rem;
  color: #888;
  font-weight: 500;
`;

const CopyToClipboardAdornmenet = ({ value, position = 'end', popperPlacement = 'top', ...props }) => {

  const { t } = useTranslation(['component']);

  const [open, setOpen] = useState(false);
  const buttonRef = useRef();

  const timeout = useRef();
  const onCopy = () => {
    setOpen(true);
    clearTimeout(timeout.current);
    timeout.current = setTimeout(() => setOpen(false), 1000);
  };

  return (
    <>
      <InputAdornment position={position} {...props}>
        <CopyToClipboard text={value} onCopy={onCopy}>
          <IconButton ref={buttonRef} title={t('copyToClipboardAdornment.copyToClipboard')}>
            <ContentCopyRoundedIcon />
          </IconButton>
        </CopyToClipboard>
      </InputAdornment>

      <Popper open={open} anchorEl={buttonRef.current} transition disablePortal placement={popperPlacement}>
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <PopperContent>
              {t('copyToClipboardAdornment.copied')}
            </PopperContent>
          </Grow>
        )}
      </Popper>
    </>
  );
};

CopyToClipboardAdornmenet.propTypes = {
  position: InputAdornment.propTypes.position,
  value: PropTypes.string.isRequired,
  popperPlacement: Popper.propTypes.placement,
};


export default CopyToClipboardAdornmenet;
