import React from 'react';
import ColorfulButton from 'components/ColorfulButton';
import PaperclipIcon from 'components/Icons/Paperclip';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { extractUrlExtension, humanFileSize } from 'services/fileService';
import styled from '@emotion/styled/macro';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';

const config = {
  shouldForwardProp: prop => !['preview'].includes(prop),
};

const StyledFile = styled(ColorfulButton, config)`
  max-width: 100%;
  min-width: 100px;
  justify-content: flex-start;
  padding: 0.25rem 0.75rem;

  .MuiButton-endIcon {
    margin-left: auto;
  }

  ${({ fullWidth }) => fullWidth && 'width: 100%;'}

  ${({ preview }) => preview && 'cursor: default;'}
`;

const FileName = styled('span')`
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 600;
`;

const FileSize = styled('span')`
  color: #666;
  font-weight: 400;
`;

const StyledIconButton = styled(IconButton)`
  margin: -3px 0;
`;

const File = ({ url, name, size, preview = false, deletable, onDelete, buttonSize, ...rest }) => {
  const { t } = useTranslation(['common', 'component']);

  const ext = !name && url
    ? extractUrlExtension(url)
    : null;

  const suffix = ext ? ` (${ext})` : null;

  let props = {
    preview,
  };

  if (!preview) {
    props = {
      ...props,
      component: 'a',
      href: url,
      target: '_blank',
      rel: 'noopener noreferrer nofollow',
    };
  } else {
    props = {
      ...props,
      component: 'span',
      disableRipple: true,
    };
  }

  if (deletable) {
    props = {
      ...props,
      endIcon: (
        <StyledIconButton onClick={onDelete} size="small" className="no-drag" aria-label={t('files.deleteThisGroup')}>
          <DeleteIcon />
        </StyledIconButton>
      ),
    };
  }


  return (
    <StyledFile
      size={buttonSize}
      active
      startIcon={<PaperclipIcon />}
      {...props}
      {...rest}
    >
      <FileName hidden={!!name}>{t('component:post.download')}{suffix}</FileName>
      <FileName hidden={!name} title={name}>{name}</FileName>
      <FileSize hidden={!size}>&nbsp;({humanFileSize(size, t)})</FileSize>
    </StyledFile>
  );
};

File.propTypes = {
  url: PropTypes.string,
  name: PropTypes.string,
  size: PropTypes.number,

  buttonSize: PropTypes.string,

  color: PropTypes.oneOf(['default', 'secondary']),

  fullWidth: PropTypes.bool,
  preview: PropTypes.bool,
  deletable: PropTypes.bool,
  onDelete: PropTypes.func,
};

export default File;
