import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Grid from 'components/Grid';
import styled from '@emotion/styled/macro';
import uniqueId from 'lodash/uniqueId';
import Paper from '@mui/material/Paper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Popper from '@mui/material/Popper';
import reactionService from 'services/reactionService';
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';
import ReactionDialog from './ReactionDialog';
import Button from './Button';

const GridItem = styled(Grid)`
  display: flex;
  align-items: center;
  font-weight: 600;
  color: inherit;
  text-decoration: none;

  svg {
    margin-right: 0.4em;
  }
`;

const StackedButton = styled('button')`
  display: flex;
  align-items: center;
  font-weight: 600;
  color: inherit;
  border: none;
  text-decoration: none;
  background: none;
  cursor: pointer;
  padding: 0.25rem 0.5rem;

  svg {
    font-size: 1.25em;
    margin-right: 0.4em
  }

  & > svg + svg {
    margin-left: -0.7em;
  }
`;

const PopperPaper = styled(Paper)`
  padding: 1em;
  margin: 0.5em;
  font-size: 0.9rem;

  svg {
    margin-right: 0.4em;
  }
`;

const iconMap = reaction => reactionService.getReaction(reaction)?.Icon;

const ReactionIconsWrapper = ({ reactionableType, reactionableId, viewReactions, children }) => {
  const [viewReactionsOpen, setViewReactionsOpen] = useState(false);

  if (viewReactions) {
    return (
      <>
        <Button
          unstyled
          style={{
            padding: '0.25rem 0.5rem',
            margin: '-0.25rem -0.5rem',
          }}
          onClick={() => setViewReactionsOpen(true)}
        >
          {children}
        </Button>
        <ReactionDialog
          reactionableType={reactionableType}
          reactionableId={reactionableId}
          open={viewReactionsOpen}
          onClose={() => setViewReactionsOpen(false)}
        />
      </>
    );
  }

  return children;
};

ReactionIconsWrapper.propTypes = {
  reactionableType: PropTypes.any,
  reactionableId: PropTypes.any,
  viewReactions: PropTypes.bool,
};

const ReactionStack = ({ reactionableType, reactionableId, reactions, viewReactions, ...rest }) => {
  const [viewReactionsOpen, setViewReactionsOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const anchorEl = useRef();
  const timeout = useRef();

  const icons = Object.keys(reactions)
    .filter(key => reactions[key] !== 0)
    .map(iconMap)
    .filter(ico => !!ico);

  if (icons.length === 0) {
    return null;
  }

  const count = Object.values(reactions).reduce((a, b) => a + b, 0);

  const replaceTimeout = (...args) => {
    clearTimeout(timeout.current);
    timeout.current = setTimeout(...args);
  };
  const hoverProps = {
    onClick: () => {
      clearTimeout(timeout.current);
      if (!viewReactions) {
        setOpen(o => !o);
      } else {
        setOpen(false);
        setViewReactionsOpen(true);
      }
    },
    onMouseEnter: () => replaceTimeout(() => setOpen(true), 300),
    onMouseLeave: () => replaceTimeout(() => setOpen(false), 200),
  };

  return (
    <>
      <ClickAwayListener onClickAway={() => setOpen(false)}>
        <StackedButton type="button" {...hoverProps} ref={anchorEl} {...rest}>
          {icons.map(Icon => (<Icon key={uniqueId()} />))}
          {count}
        </StackedButton>
      </ClickAwayListener>
      <Popper open={open} anchorEl={anchorEl.current} transition placement="top">
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <PopperPaper>
              <ReactionRow reactions={reactions} />
            </PopperPaper>
          </Grow>
        )}
      </Popper>
      <ReactionDialog
        reactionableType={reactionableType}
        reactionableId={reactionableId}
        open={viewReactionsOpen}
        onClose={() => setViewReactionsOpen(false)}
      />
    </>
  );
};

ReactionStack.propTypes = {
  reactionableType: PropTypes.any,
  reactionableId: PropTypes.any,
  reactions: PropTypes.object.isRequired,
  viewReactions: PropTypes.bool,
};

const ReactionRow = React.forwardRef(({ reactionableType, reactionableId, reactions, viewReactions }, ref) => {
  const filtered = Object.keys(reactions)
    .filter(key => reactions[key] !== 0)
    .filter(key => !!iconMap(key))
    .sort((a, b) => (reactions[b] - reactions[a]) || reactionService.reactionsArray.indexOf(a) - reactionService.reactionsArray.indexOf(b));

  const icons = filtered.reduce((acc, key) => ([
    ...acc,
    {
      key,
      Icon: iconMap(key),
      count: reactions[key],
    },
  ]), []);

  const { t } = useTranslation('component');

  return (
    <>
      <ReactionIconsWrapper
        reactionableType={reactionableType}
        reactionableId={reactionableId}
        viewReactions={viewReactions}
      >
        <Grid container alignItems="center" wrap="nowrap" spacing={2} ref={ref}>
          {icons.map(({ key, Icon, count }) => (
            <GridItem item key={key}>
              <Tooltip title={reactionService.getReaction(key).getTranslation(t)} placement="top" disableInteractive>
                <Icon fontSize="small" />
              </Tooltip>
              {count}
            </GridItem>
          ))}
        </Grid>
      </ReactionIconsWrapper>
    </>
  );
});

ReactionRow.propTypes = {
  reactionableType: PropTypes.any,
  reactionableId: PropTypes.any,
  reactions: PropTypes.object.isRequired,
  viewReactions: PropTypes.bool,
};

const ReactionBar = ({
  compact,
  reactionableType = null,
  reactionableId = null,
  reactions = {},
  viewReactions = false,
}) => {

  if (!reactions) {
    return null;
  }

  return compact
    ? (
      <ReactionStack
        reactionableType={reactionableType}
        reactionableId={reactionableId}
        reactions={reactions}
        viewReactions={viewReactions}
      />
    )
    : (
      <ReactionRow
        reactionableType={reactionableType}
        reactionableId={reactionableId}
        reactions={reactions}
        viewReactions={viewReactions}
      />
    );
};

ReactionBar.propTypes = {
  compact: PropTypes.bool,
  reactions: PropTypes.shape({
    like: PropTypes.number,
    love: PropTypes.number,
    think: PropTypes.number,
  }),
  reactionableType: PropTypes.any,
  reactionableId: PropTypes.any,
  viewReactions: PropTypes.bool,
};

export default ReactionBar;
