import React from 'react';
import Tabbar from 'containers/Tabbar/Tabbar';
import MobileNavbar from 'components/MobileNavbar';
import MaxWidthContainer from 'components/MaxWidthContainer';
import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';

const RootContainer = styled(MaxWidthContainer)`
  width: 100%;
  height: 100%;

  margin-top: 1.5rem;
`;

const MobileCommonLayout = ({ children, navbarProps = {}, tabbar = true, ...rest }) => {
  return (
    <RootContainer {...rest}>
      {navbarProps && <MobileNavbar {...navbarProps} />}
      {children}
      {tabbar && <Tabbar />}
    </RootContainer>
  );
};

MobileCommonLayout.propTypes = {
  tabbar: PropTypes.bool,
  navbarProps: PropTypes.object,

  disableGutters: PropTypes.bool,
};

export default MobileCommonLayout;
