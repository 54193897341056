import combineReducers from 'combine-reducers-global-state';
import { addToPagination, createPagination } from 'redux/helpers/pagination';
import types from './types';

const coursesForMember = (state = {}, { type, payload }) => {
  switch (type) {
    case types.getCoursesForMember:
      if (!payload.page || payload.page === 1) {
        return { ...state, [payload.feedIdentifier ?? payload.filter]: createPagination(null, 'courseIds') };
      }

      return state;
    case types.getCoursesForMemberSuccess:
      return addToPagination(state, payload.feedIdentifier ?? payload.filter, payload, 'courseIds');

    default:
      return state;
  }
};

const coursesForAdmin = (state = createPagination(null, 'courseIds'), { type, payload }) => {
  switch (type) {
    case types.getCoursesForAdminSuccess:
      return addToPagination(state, null, payload, 'courseIds');
    default:
      return state;
  }
};

const courseReducers = combineReducers({
  coursesForMember,
  coursesForAdmin,
});

export default { course: courseReducers };
