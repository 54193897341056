import React from 'react';
// This package is the reason for findDOMNode exception in console:
import ReactProgressiveImage from 'react-progressive-graceful-image';
import PropTypes from 'prop-types';

const renderProgressiveImage = (children, src, loading, { srcSet, sizes }) => children({
  src,
  srcSet: srcSet || undefined,
  sizes: sizes || undefined,
  loading,
});

const ProgressiveImage = ({ children, src, srcSet, sizes, ...rest }) => {

  const srcSetProps = { };

  if (srcSet) {
    srcSetProps.srcSetData = { srcSet, sizes };
  }

  return (
    <ReactProgressiveImage src={src} {...srcSetProps} {...rest}>
      {(...args) => renderProgressiveImage(children, ...args)}
    </ReactProgressiveImage>
  );
};

ProgressiveImage.propTypes = {
  src: PropTypes.string,
  srcSet: PropTypes.string,
  sizes: PropTypes.string,
};

export default ProgressiveImage;
