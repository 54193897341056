import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { postSelectors } from 'redux/post';
import { PostInfoChannel } from 'components/Post';
import PropTypes from 'prop-types';
import Grid from 'components/Grid';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import ProgressiveImage from 'components/ProgressiveImage';
import Avatar from 'components/Avatar';
import Stack from '@mui/material/Stack';
import { channelSelectors } from 'redux/channel';
import DateFormat from 'components/DateFormat';
import ScheduleRoundedIcon from '@mui/icons-material/ScheduleRounded';

const BaseImg = styled('img')`
  background-color: #fff;
  object-fit: cover;
  object-position: center center;

  height: 50px;
  width: 75px;

  border-radius: 4px;

  object-fit: cover;
  object-position: center center;
  background-color: #fff;

  vertical-align: middle;

  border: 1px solid rgba(0, 0, 0, 0.06);;
`;

const Name = styled('div')`
  font-weight: 500;

  padding-left: 0.5rem;
`;

const Content = styled('div')`
  font-size: 12px;
  color: #888;

  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box;
`;

const InfoStack = styled(Stack)`
  font-size: 12px;

  max-width: 100%;

  & > * {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const Channel = styled('div')`

`;

const ThumbnailImage = ({ src, ...rest }) => {

  const theme = useTheme();

  const [imageError, setImageError] = useState(false);

  // Reset errors when src should change
  useEffect(() => {
    setImageError(false);
  }, [src]);

  if (imageError) {
    return null;
  }

  return (
    <ProgressiveImage src={src?.src || src} srcSet={src?.srcSet} sizes={theme.feedImageSrcSetSizes}>
      {({ loading, ...image }) => <BaseImg draggable="false" {...image} onError={setImageError} {...rest} />}
    </ProgressiveImage>
  );
};

ThumbnailImage.propTypes = {
  src: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      src: PropTypes.string,
      srcSet: PropTypes.string,
    }),
  ]),
};

const Separated = ({ children }) => <>&#65372;{children}</>;

const MiniPostPreview = ({ postId, feedName, ...rest }) => {

  const post = useSelector(state => postSelectors.getPost(state, postId));

  const thumbnailSrc = useMemo(() => {
    if (post?.media?.length > 0) {
      const item = post.media[0];
      if (item.type === 'video') {
        return item.poster;
      }

      return item.src;
    }
    if (post?.opengraph) {
      return post.opengraph.image;
    }
  }, [post?.media, post?.opengraph]);

  const getChannelSelector = useSelector(channelSelectors.getChannelSelector);

  if (!post || post.deleted) {
    return null;
  }

  const { user, channel: channelId, scheduledAt } = post;

  const channel = getChannelSelector(channelId);

  return (
    <Grid container direction="row" wrap="nowrap" alignItems="center" spacing={1} {...rest}>

      <Grid item xs zeroMinWidth alignSelf="flex-start">
        <Grid container direction="column" wrap="nowrap" spacing={0.5} alignItems="flex-start">
          <Grid item sx={{ '&&': { maxWidth: '100%' } }}>
            <InfoStack direction="row" wrap="nowrap" spacing={0.25} alignItems="center">
              <Avatar size={16} firstName={user?.firstName} lastName={user?.lastName} src={user?.avatar?.src} srcSet={user?.avatar?.srcSet} tabIndex={-1} />
              <Name>{`${user?.firstName} ${user?.lastName}`}</Name>
              {channel?.name && <Separated><Channel><PostInfoChannel channel={channel} link={false} /></Channel></Separated>}
              <Separated>
                <ScheduleRoundedIcon fontSize="small" sx={{ fontSize: '16px' }} />
                <DateFormat date={scheduledAt} fromNowDuring={24 * 60 * 60} format="Pp" />
              </Separated>
            </InfoStack>
          </Grid>
          <Grid item>
            <Content>{post.body}</Content>
          </Grid>
        </Grid>
      </Grid>

      {thumbnailSrc && (
        <Grid item>
          <ThumbnailImage src={thumbnailSrc} />
        </Grid>
      )}
    </Grid>
  );
};

MiniPostPreview.propTypes = {
  postId: PropTypes.any,
  feedName: PropTypes.string,
};

export default MiniPostPreview;
