import React, { useMemo } from 'react';
import { postTypes } from 'redux/post';
import PostForm from 'containers/Post/PostForm/PostForm';
import PropTypes from 'prop-types';
import useSmartPostCreateInitialValues from 'hooks/useSmartPostCreateInitialValues';

const CreatePostForm = ({ initialValues, ...rest }) => {

  const smartInitialValues = useSmartPostCreateInitialValues();

  const combinedInitialValues = useMemo(() => ({
    ...smartInitialValues,
    ...initialValues,
  }), [smartInitialValues, initialValues]);

  return (
    <PostForm
      initialValues={combinedInitialValues}
      start={postTypes.store}
      reject={postTypes.storeFailure}
      resolve={postTypes.storeSuccess}
      {...rest}
    />
  );
};

CreatePostForm.propTypes = {
  initialValues: PropTypes.object,
};

export default CreatePostForm;
