import React, { useContext, useRef } from 'react';
import DrawerBody from 'containers/Side/Drawer/DrawerBody';
import Tablist from 'components/Tablist/Tablist';
import Tab from 'components/Tablist/Tab';
import { useTranslation } from 'react-i18next';
import Grid from 'components/Grid';
import { NumberParam, useQueryParam } from 'use-query-params';
import styled from '@emotion/styled/macro';
import useFeature from 'hooks/useFeature';
import ViewContext from '../ViewContext';
import Birthday from './Birthday/Birthday';
import Birthdays from './Birthday/Birthdays';
import JobAnniversaries from './JobAnniversary/JobAnniversaries';
import JobAnniversary from './JobAnniversary/JobAnniversary';
import { getBirthdaysLink, getJobAnniversariesLink } from './memberEventsService';

const Wrapper = styled('div')`
  margin: 1rem 0;
`;

const MemberEvents = () => {

  const { t } = useTranslation(['component']);

  const scrollBody = useRef();

  const { side } = useContext(ViewContext);
  const [memberId] = useQueryParam('memberId', NumberParam);

  const birthdayFeature = useFeature('birthdays');
  const jobAnniversaryFeature = useFeature('jobAnniversaries');

  if (side === 'birthdays' && memberId) {
    return <Birthday memberId={memberId} />;
  }

  if (side === 'jobAnniversaries' && memberId) {
    return <JobAnniversary memberId={memberId} />;
  }

  return (
    <DrawerBody ref={scrollBody}>
      <Wrapper>
        <Grid container direction="column" wrap="nowrap" spacing={2}>
          {birthdayFeature.enabled && jobAnniversaryFeature.enabled && (
            <Grid item>
              <Tablist>
                {birthdayFeature.enabled && <Tab to={getBirthdaysLink} isActive={() => side === 'birthdays'}>{t('birthdayEvents.title')}</Tab>}
                {jobAnniversaryFeature.enabled && <Tab to={getJobAnniversariesLink} isActive={() => side === 'jobAnniversaries'}>{t('jobAnniversaryEvents.title')}</Tab>}
              </Tablist>
            </Grid>
          )}
          <Grid item>
            {side === 'birthdays' && <Birthdays useWindow={false} getScrollParent={() => scrollBody.current} />}
            {side === 'jobAnniversaries' && <JobAnniversaries useWindow={false} getScrollParent={() => scrollBody.current} />}
          </Grid>
        </Grid>
      </Wrapper>
    </DrawerBody>
  );
};

export default MemberEvents;
