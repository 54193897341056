/* eslint-disable react/prop-types */
import React from 'react';
import TextLoader from 'components/TextLoader';
import InfiniteScroll from 'react-infinite-scroller';
import PropTypes from 'prop-types';
import EmptyState from 'components/EmptyState';
import EndOfSomething from 'components/EndOfSomething';
import Comment from 'containers/Comment/Comment';
import { useTranslation } from 'react-i18next';
import Grid from 'components/Grid';

const CommentFeed = ({ commentIds = [], loadMore, hasMore, currentPage, useWindow = true, getScrollParent, texts = {}, compact, disableEndText, ...rest }) => {

  const { t } = useTranslation(['component']);

  const { loading, loadingMore, noComments, noMoreComments, noCommentsSubtitle } = texts;

  const loaderProps = {
    loadMore,
    hasMore,
    loader: <TextLoader
      key="0"
      text={commentIds.length === 0 && hasMore
        ? (loading || t('commentFeed.loading'))
        : (loadingMore || t('commentFeed.loadingMore'))}
    />,
    useWindow,
    getScrollParent,
  };

  return (
    <InfiniteScroll {...loaderProps} {...rest}>
      <Grid container direction="column" wrap="nowrap" spacing={2}>
        {commentIds.map(commentId => <Grid item key={commentId}><Comment compact={compact} commentId={commentId} /></Grid>)}
        {!hasMore && commentIds.length === 0 && <Grid item><EmptyState title={noComments || t('commentFeed.noComments')} subtitle={noCommentsSubtitle} /></Grid>}
        {!disableEndText && !hasMore && commentIds.length > 0 && <Grid item><EndOfSomething>{noMoreComments || t('commentFeed.noMoreComments')}</EndOfSomething></Grid>}
      </Grid>
    </InfiniteScroll>
  );
};

InfiniteScroll.propTypes = {
  ...InfiniteScroll.propTypes,
  // Hack. Their prop-type defnition is rather restricted.
  // Seems to work just as well with React components.
  // This is here to get rid of the error.
  element: PropTypes.any,
};

CommentFeed.propTypes = {
  element: PropTypes.any,
  commentIds: PropTypes.arrayOf(PropTypes.number),
  loadMore: PropTypes.func.isRequired,
  hasMore: PropTypes.bool.isRequired,
  currentPage: PropTypes.number,
  useWindow: PropTypes.bool,
  getScrollParent: PropTypes.func,

  compact: PropTypes.bool,

  disableEndText: PropTypes.bool,

  texts: PropTypes.shape({
    loading: PropTypes.string,
    loadingMore: PropTypes.string,
    noComments: PropTypes.string,
    noCommentsSubtitle: PropTypes.string,
    noMoreComments: PropTypes.string,
  }),
};

export default CommentFeed;
