import React, { useEffect } from 'react';
import Grid from 'components/Grid';
import BirthdayWidget from 'containers/BirthdayAndJobAnniversaryWidget/BirthdayWidget';
import JobAnniversaryWidget from 'containers/BirthdayAndJobAnniversaryWidget/JobAnniversaryWidget';
import { useDispatch, useSelector } from 'react-redux';
import { birthdayActions, birthdaySelectors } from 'redux/birthday';
import { jobAnniversaryActions, jobAnniversarySelectors } from 'redux/jobAnniversary';
import useFeature from 'hooks/useFeature';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { isMobile } from 'helpers';
import HorizontalScroller from 'components/HorizontalScroller';
import styled from '@emotion/styled/macro';

const applyScrollerStyles = el => styled(el)`
  max-width: 100%;
  min-width: 200px;
`;

const ScrollingBirthdayWidget = applyScrollerStyles(BirthdayWidget);
const ScrollingJobAnniversaryWidget = applyScrollerStyles(JobAnniversaryWidget);

const BirthdayAndJobAnniversaryWidget = (args) => {
  const dispatch = useDispatch();

  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const currentBirthdays = useSelector(birthdaySelectors.getBirthdayEventsOverviewToday);
  const upcomingBirthdays = useSelector(birthdaySelectors.getBirthdayEventsOverviewUpcoming);
  const birthdays = currentBirthdays.concat(upcomingBirthdays);

  const currentJobAnniversaries = useSelector(jobAnniversarySelectors.getJobAnniversaryEventsOverviewToday);
  const upcomingJobAnniversaries = useSelector(jobAnniversarySelectors.getJobAnniversaryEventsOverviewUpcoming);
  const jobAnniversaries = currentJobAnniversaries.concat(upcomingJobAnniversaries);

  const birthdayFeature = useFeature('birthdays');
  const jobAnniversaryFeature = useFeature('jobAnniversaries');

  const enabled = birthdayFeature.enabled || jobAnniversaryFeature.enabled;

  useEffect(() => {
    if (birthdayFeature.enabled) {
      dispatch(birthdayActions.getOverview());
    }
    if (jobAnniversaryFeature.enabled) {
      dispatch(jobAnniversaryActions.getOverview());
    }
  }, [dispatch, birthdayFeature.enabled, jobAnniversaryFeature.enabled]);

  const showBirthdays = birthdays.length > 0 && birthdayFeature.enabled;
  const showJobAnniversaries = jobAnniversaries.length > 0 && jobAnniversaryFeature.enabled;

  // Fake key to trigger re-renders when data changes
  const renderKey = showBirthdays + showJobAnniversaries;

  if (!enabled || (!showBirthdays && !showJobAnniversaries)) {
    return null;
  }

  if (isMobile && smallScreen && showBirthdays && showJobAnniversaries) {
    return (
      <HorizontalScroller {...args}>
        {showBirthdays && <ScrollingBirthdayWidget key={renderKey} />}
        {showJobAnniversaries && <ScrollingJobAnniversaryWidget key={renderKey} />}
      </HorizontalScroller>
    );
  }

  return (
    <div {...args}>
      <Grid container spacing={2}>
        {showBirthdays && <Grid item sm={showJobAnniversaries ? 6 : 12} xs={12}><BirthdayWidget key={renderKey} /></Grid>}
        {showJobAnniversaries && <Grid item sm={showBirthdays ? 6 : 12} xs={12}><JobAnniversaryWidget key={renderKey} /></Grid>}
      </Grid>
    </div>
  );
};

export default BirthdayAndJobAnniversaryWidget;
